export default {
	startsWith: 'يبدأ بـ',
	contains: 'يحتوي على',
	notContains: 'لا يحتوي على',
	endsWith: 'ينتهي بـ',
	equals: 'يساوي',
	notEquals: 'لا يساوي',
	noFilter: 'لا يوجد مرشح',
	lt: 'أقل من',
	lte: 'أصغر من أو يساوي',
	gt: 'أكثر من',
	gte: 'أكبر من أو يساوي',
	dateIs: 'التاريخ هو',
	dateIsNot: 'التاريخ ليس كذلك',
	dateBefore: 'التاريخ قبل',
	dateAfter: 'التاريخ هو بعد',
	clear: 'واضح',
	apply: 'يتقدم بطلب',
	matchAll: 'تطابق الكل',
	matchAny: 'تطابق أي',
	addRule: 'إضافة قاعدة',
	removeRule: 'إزالة القاعدة',
	accept: 'نعم',
	reject: 'لا',
	choose: 'أختر',
	upload: 'رفع',
	cancel: 'يلغي',
	dayNames: ['يوم الأحد‎', 'يوم الإثنين‎', 'يوم الثلاثاء‎', '‏يوم الأَرْبعاء‎', '‏يوم الخَمِيس‎', 'يوم الجُمْعَة‎‎', 'يوم السَّبْت'],
	dayNamesShort: ['الأحد‎', 'الإثنين‎', 'الثلاثاء‎', 'الأَرْبعاء‎', 'الخَمِيس‎', 'الجُمْعَة‎‎', 'السَّبْت'],
	dayNamesMin: ['الأحد‎', 'الإثنين‎', 'الثلاثاء‎', 'الأَرْبعاء‎', 'الخَمِيس‎', 'الجُمْعَة‎‎', 'السَّبْت'],
	monthNames: ['ديسمبر', 'نوفمبر', 'أكتوبر', 'سبتمبر', 'أغسطس', 'يوليو', 'يونيو', 'مايو', 'ابريل', 'مارس', 'فبراير', 'يناير'],
	monthNamesShort: ['ديسمبر', 'نوفمبر', 'أكتوبر', 'سبتمبر', 'أغسطس', 'يوليو', 'يونيو', 'مايو', 'ابريل', 'مارس', 'فبراير', 'يناير'],
	today: 'اليوم',
	weekHeader: 'الأسبوع',
	firstDayOfWeek: 1,
	dateFormat: 'dd/mm/yy',
	weak: 'ضعيف',
	medium: 'متوسط',
	strong: 'قوي',
	passwordPrompt: 'أدخل كلمة المرور',
	emptyFilterMessage: 'لم يتم العثور على نتائج',
	emptyMessage: 'لا توجد خيارات متاحة',
	validations: {
		required: "Value is required.",
		undefined: "Value is required.",
		email: "Value is not a valid email address",
		minLength: "This field should be at least ${min} characters long",
		maxLength: "The maximum length allowed is ${max}",
		minValue: "The minimum value allowed is ${min}",
		maxValue: "The maximum value allowed is ${max}",
		numeric: "Value must be numeric",
		ipAddress: "The value is not a valid IP address",
		sameAs: "The value must be equal to the ${otherName} value",
	}
}