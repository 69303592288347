import { createStore } from "vuex"
import { app } from "./app.js"
//import appStore from "./app_store.js";
import { auth } from "./auth.js"
import { kontak } from "./kontak.js"
import { login } from "./login.js"

//const { state, getters, mutations, actions } = appStore;

const store = createStore({
	modules: {
		app,
		auth,
		kontak,
		login
	},
	// enable strict mode (adds overhead!)
	// for dev mode only
	//strict: process.env.DEV
});
export default store;