export default {
	startsWith: 'Starts with',
	contains: 'Contains',
	notContains: 'Not contains',
	endsWith: 'Ends with',
	equals: 'Equals',
	notEquals: 'Not equals',
	noFilter: 'No Filter',
	lt: 'Less than',
	lte: 'Less than or equal to',
	gt: 'Greater than',
	gte: 'Greater than or equal to',
	dateIs: 'Date is',
	dateIsNot: 'Date is not',
	dateBefore: 'Date is before',
	dateAfter: 'Date is after',
	clear: 'Clear',
	apply: 'Apply',
	matchAll: 'Match All',
	matchAny: 'Match Any',
	addRule: 'Add Rule',
	removeRule: 'Remove Rule',
	accept: 'Yes',
	reject: 'No',
	choose: 'Choose',
	upload: 'Upload',
	cancel: 'Cancel',
	dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
	monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	today: 'Today',
	weekHeader: 'Wk',
	firstDayOfWeek: 0,
	dateFormat: 'yy-mm-dd',
	weak: 'Weak',
	medium: 'Medium',
	strong: 'Strong',
	passwordPrompt: 'Enter a password',
	emptyFilterMessage: 'No results found',
	emptyMessage: 'No available options',

	validations: {
		required: "Value is required.",
		undefined: "Value is required.",
		email: "Value is not a valid email address",
		minLength: "This field should be at least ${min} characters long",
		maxLength: "The maximum length allowed is ${max}",
		minValue: "The minimum value allowed is ${min}",
		maxValue: "The maximum value allowed is ${max}",
		numeric: "Value must be numeric",
		ipAddress: "The value is not a valid IP address",
		sameAs: "The value must be equal to the ${otherName} value",
	}
}