
import { createRouter, createWebHashHistory } from 'vue-router';
export default ({ store, auth }) => {
	let routes = [
		//Dashboard routes


//kontak routes
			{
				path: '/kontak/:index?/:fieldName?/:fieldValue?',
				name: 'kontaklist',
				component: () => import('./pages/kontak/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/kontak/view/:id', 
				name: 'kontakview', 
				component: () => import('./pages/kontak/view.vue'), 
				props: true
			},
	
			{ 
				path: '/kontak/add', 
				name: 'kontakadd', 
				component: () => import('./pages/kontak/add.vue'), 
				props: true
			},
	
			{ 
				path: '/kontak/edit/:id', 
				name: 'kontakedit', 
				component: () => import('./pages/kontak/edit.vue'), 
				props: true
			},
		
			{
				path: '/kontak/list_index/:index?/:fieldName?/:fieldValue?',
				name: 'kontaklist_index',
				component: () => import('./pages/kontak/list_index.vue'), 
				props: true
			},
			
	

//login routes
			{
				path: '/login/:index?/:fieldName?/:fieldValue?',
				name: 'loginlist',
				component: () => import('./pages/login/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/login/view/:id', 
				name: 'loginview', 
				component: () => import('./pages/login/view.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'loginaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'loginaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/login/add', 
				name: 'loginadd', 
				component: () => import('./pages/login/add.vue'), 
				props: true
			},
	
			{ 
				path: '/login/edit/:id', 
				name: 'loginedit', 
				component: () => import('./pages/login/edit.vue'), 
				props: true
			},
		

		
		
		
		
		{ 
			path:  '/error/forbidden', 
			name: 'forbidden', 
			component: () => import('./pages/errors/forbidden.vue'),
			props: true
		},
		{ 
			path: '/error/notfound', 
			name: 'notfound',
			component: () => import('./pages/errors/pagenotfound.vue'),
			props: true
		},
		{
			path: '/:catchAll(.*)', 
			component: () => import('./pages/errors/pagenotfound.vue')
		}
	];
	
	const user = store.state.auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}


	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			//if(savedPostion) return savedPostion;
			return { x:0, y:0 }
		}
	});

	
	router.beforeEach((to, from, next) => {
		const user = store.state.auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
